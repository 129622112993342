import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Terms & Conditions">
      <p dir="ltr">Snickers Contest Terms &amp; Conditions</p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">Contest Details, Concept and Participation</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            These terms and conditions apply to the "Snickers Smartphone"
            Contest for consumers (hereinafter referred to as the “Contest")
            sponsored and launched by MARS INTERNATIONAL INDIA PVT. LTD. (having
            its registered office at: 4658- A, No. 21, Ansari Road, Darya Ganj,
            New Delhi – 110002, India) wherein consumers can participate by
            registering on the Contest website
            www.snickerscontest.bigcityexperience.com (“Contest Website”).
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            The Contest is valid only in India, except Union Territory of
            Puducherry and any other State/Union Territory in India where
            prohibited by law, from 15/01/2024 to 15/04/2024 (herein after
            referred as "Contest Period").
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">During the Contest Period:</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            80 winners will be selected from Rest of India and 10 winners will
            be selected from the participants from Tamil Nadu to win Samsung
            Flip Smartphone
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            10 winners will be selected from Rest of India and 2 winners will be
            selected from the participants from Tamil Nadu to win Samsung Smart
            Watch
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            2 winners will be selected from Rest of India and 1 winner will be
            selected from the participants from Tamil Nadu to win Samsung Fold
            Smartphone
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            All intellectual property rights including trademark, copyright and
            designs used in the Contest and/or materials associated therewith
            are the property of their respective owners.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            The Contest is subject to all applicable central, state, and local
            laws and regulations.
          </p>
        </li>
      </ol>
      <br />
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Consumers participating or seeking to participate in the Contest
            shall individually be referred to as a "Participant" and
            collectively as the "Participants".
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">Agreement to Terms and Conditions</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            By participating in this Contest, Participants fully and
            unconditionally agree to and accept these Terms and Conditions
            available on the Contest Website at
            www.snickerscontest.bigcityexperience.com. The decisions of MARS
            INTERNATIONAL INDIA PVT. LTD. will be final and binding in all
            matters related to the Contest. Successfully entering the Contest
            and winning a prize is subject to compliance with all requirements
            set forth herein and as may be requested by MARS INTERNATIONAL INDIA
            PVT. LTD.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. reserves the right to exclude any
            person from the Contest on grounds of misconduct or criminal record
            or for any other reasons, as it may deem fit and proper. No
            communication in this regard will be entertained by MARS
            INTERNATIONAL INDIA PVT. LTD.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. reserves the right to terminate,
            modify or extend the Contest and/or Contest Period and/or deletion
            or addition of any of the terms and conditions of the Contest, at
            any time at its absolute discretion, without advance notice and / or
            assigning any reason thereof. All decisions of MARS INTERNATIONAL
            INDIA PVT. LTD. in respect of the Contest and the prizes therein
            will be final, binding and conclusive.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">Eligibility</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            This Contest is open to all the residents of India with the
            exception of consumers/participants from State of Tamil Nadu, Union
            Territory of Puducherry and any other State/Union Territory in India
            where prohibited by law. Employees of MARS INTERNATIONAL INDIA PVT.
            LTD. and Premier Sales Promotions Private Limited and their
            respective immediate family members are not eligible to participate
            in the Contest.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            This Contest is open for consumers who have completed the age of 18
            years.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited will not be responsible for any NDNC (National Do
            Not Call) registry regulation that will come into play. All
            Participants who participate in the Contest will agree as per the
            terms and conditions that even if they are registered under NDNC,
            DND (Do Not Disturb), MARS INTERNATIONAL INDIA PVT. LTD. and/or
            Premier Sales Promotions Private Limited will have all the authority
            to call/message such shortlisted winners by virtue of them having
            voluntarily participated in the Contest and no legal action shall be
            taken by a Participant against MARS INTERNATIONAL INDIA PVT. LTD.
            and/or Premier Sales Promotions Private Limited in this regard.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">Contest Period &amp; Contest Timings</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Contest will commence from 12.00:01 AM on 15/01/2024 to 11:59:59
            PM on 15/04/2024.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Time of participation will be defined as the time at which the entry
            is received through registration on
            <a href="http://www.snickerscontest.bigcityexperience.com">
              www.snickerscontest.bigcityexperience.com
            </a>
            .
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Entries coming outside the Contest Period will not be considered for
            winner selection.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            The Contest shall run on the following stock keeping units (“SKU’s”)
            of MARS INTERNATIONAL INDIA PVT. LTD.’s Snickers chocolates on Stick
            size, snack size, and bar size. The Contest is applicable only to
            said SKU’s/promo packs and is not applicable to other packs.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">Prizes</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            During the Contest Period, 105 winners will be selected for Prizes
            for Contest Period of 90 days as per the prizes given below:
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            80 winners will be selected from Rest of India and 10 winners will
            be selected from the participants from Tamil Nadu to win Samsung
            Flip Smartphone
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            10 winners will be selected from Rest of India and 2 winners will be
            selected from the participants from Tamil Nadu to win Samsung Smart
            Watch
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            2 winners will be selected from Rest of India and 1 winner will be
            selected from the participants from Tamil Nadu to win Samsung Fold
            Smartphone
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">How to Participate</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Any participant can enter any number of times during the Contest
            Period from a mobile number.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Participants will be required to register on the Contest Website by
            visiting the website: www.snickerscontest.bigcityexperience.com.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Participants will enter a unique code (code will be mentioned on the
            specially marked MARS INTERNATIONAL INDIA PVT. LTD.’s Snickers
            chocolate promotion packs/SKU’s as mentioned above) along with other
            details to submit their registration.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall not be responsible for (including but not
            limited to):
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">For any SPAM generated messages;</p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            For the Operator Code not being displayed on the user's mobile
            phones;
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">For any SMS message delivery failures;</p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            Any lost, late or misdirected computer transmission or network,
            electronic failures or any kind of any failure to receive entries
            owing to transmission failures or due to any technical reason;
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Non-receipt of call/message due to registration of a Participant to
            the DND of the telecom provider/ National Do Not Call Registry/ any
            other restriction which a Participant has specifically requested for
            not receiving messages for the specific campaign;
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">Other conditions beyond its control.</p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">Winner Selection</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Any Participant can win a maximum of 1 prize during the Contest
            Period basis a combination of unique mobile number, valid unique
            code, unique permanent account number, unique Aadhaar number.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            From the valid entries received, Premier Sales Promotions Private
            Limited will select 105 winners who will win prizes throughout the
            Contest Period (“Winners”), subject to such Winners being the
            Confirmed Winners as stipulated herein below.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Winners from Pan India will be selected by Premier Sales Promotions
            Private Limited using randomizer software irrespective of the time
            of the entry into the Contest but subject to the same being during
            the Contest Period.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            For Tamil Nadu, From the Valid Entries received, the first response
            describing their love for Snickers will be considered for
            evaluation. Each entry eligible for evaluation will be evaluated on
            the creativity of the response (uniqueness, freshness of the
            answers) received by an independent auditor appointed by the
            Organizers. Prizes will be given subject to the Winner satisfying
            all conditions and/or the verification process of MARS INTERNATIONAL
            INDIA PVT. LTD.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            The prize cannot be transferred, exchanged, or redeemed for cash. In
            the event that a Winner does not take the prize in the time and
            manner stipulated, then the prize will be forfeited by the winner
            and cash will not be awarded in lieu of the prize. Prizes must be
            taken as offered and cannot be varied. Any unclaimed prize is
            not-transferable, non-refundable and non-redeemable for other goods
            or services and cannot be taken in exchange for cash.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Any unused prize is not-transferable, non-refundable, and
            non-redeemable for other goods and/or services and cannot be taken
            in exchange for cash.
          </p>
        </li>
      </ol>
      <br />
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. shall provide the prize on an
            "as-is basis" and without any warranty or guarantee concerning the
            quality, suitability or comfort, and MARS INTERNATIONAL INDIA PVT.
            LTD. and/or its associates, affiliates and/ or its management,
            directors, officers, agents, representatives shall not be
            responsible for or liable (including but not limited to the product
            and service liabilities) for deficiency and/ or defect of any
            product/service and/or the prize or for any kind of consequential
            damages/ loss, in any manner whatsoever. If any Participant has any
            grievance with respect to the prize, he/she may contact the
            manufacturer directly.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            The image of the prize depicted on the press
            advertisements/posters/TVC's/pack shots etc. are for illustrative
            purposes only and the actual prize may vary from the depiction made.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall not be liable for any loss or damage of the
            prize due to act of god, governmental action, force majeure
            circumstances and / or any other reasons which are beyond the
            control of MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales
            Promotions Private Limited, and under no circumstances, MARS
            INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall be liable to pay any compensation whatsoever
            for such losses.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            Any Participant of the Contest who wins any one of the prizes during
            the Contest Period shall not be eligible to win any further prizes.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <li dir="ltr">
          <p dir="ltr">
            The winner generation for prizes will be done on the basis of valid
            unique code + unique mobile number + unique permanent account number
            + unique Aadhaar number
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">Contacting Winners</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Once the Winners are selected, Premier Sales Promotions Private
            Limited shall process the distribution of prizes to the Winners.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            In the event the shortlisted Winner's number is busy, unreachable,
            or he/she does not attend the call, upto two (2) more attempts will
            be made to reach the Participant on their winning mobile number. If
            even on the third attempt, the Participant does not attend the call,
            or is unreachable, the Participant's participation in the Contest
            comes to an end and the Participant's entry shall stand
            cancelled/forfeited and the Participant becomes ineligible to
            receive the prize.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            In the event the shortlisted Winner attends the call on either the
            second or third attempt, the procedure outlined in step 8 (d) herein
            below shall apply.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            In case of a positive response from the shortlisted Winner, each
            such shortlisted Winner will be required to send the following
            documents and information:
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Copy of Address proof (Electricity bill or Mobile Bill)
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Copies of Aadhaar Card &amp; PAN Card mandatory</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No objection/claim certificate from the Winner after claiming prize
          </p>
        </li>
      </ol>
      <p dir="ltr">
        The above (i) to (iii) are referred to as the “Participation Package”
        for the Winner. A Participant agrees to share the Participant Package
        with MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
        Private Limited and/or their respective authorised representatives. The
        completed Participation Package needs to be sent by email to Premier
        Sales Promotions Private Limited at:{" "}
        <a href="mailto:win@bigcity.in">win@bigcity.in</a> 2 days of the call
        made/message sent by Premier Sales Promotions Private Limited and/or its
        authorised representative to the selected Winner.
      </p>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Upon receipt of the Participation Package, MARS INTERNATIONAL INDIA
            PVT. LTD. and/or Premier Sales Promotions Private Limited shall
            scrutinize all documents. If all the documents submitted are in
            order and valid then a Participant will be treated as a Confirmed
            Winner.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            No prize will be awarded if the information presented/submitted by
            the Participant(s) at the time of entering the Contest is not
            factually correct.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall not be responsible for any accident or mishap
            or loss of the Participation Package by the Participant in e-mail
            transit.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            The responsibility of ensuring that the Participation Package
            reaches the specified e-mail Id above lies solely with the
            Participants.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            No justification/explanation for non-receipt of the Participation
            Package or any missing documents shall be accepted by MARS
            INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            In the event of death of the Confirmed Winner, no claim from the
            nominee or his/her legal heirs of the Confirmed Winner shall be
            entertained for receiving the prize.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">Publicity</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Participant/s and/or Winner’s undertake and irrevocably and
            unconditionally permit MARS INTERNATIONAL INDIA PVT. LTD. and/or its
            authorised representatives to cover the Contest through various
            media including newspapers, radio television news channels,
            internet, point of sale materials, etc., and shall not raise any
            objection, protest or demur to such coverage or in this regard.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            The Winners must, at the request of MARS INTERNATIONAL INDIA PVT.
            LTD. and/or its authorised representatives, participate in all
            promotional activity (such as publicity and photography) surrounding
            the winning of the prize, free of charge, and they consent to MARS
            INTERNATIONAL INDIA PVT. LTD. using their name and image in
            promotional material.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">Intellectual Property</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            All right, title and interest, including but not limited to the
            intellectual property rights, in the promotional material(s) and in
            any and all responses received shall vest solely and exclusively
            with MARS INTERNATIONAL INDIA PVT. LTD. at all times. MARS
            INTERNATIONAL INDIA PVT. LTD. or any person or entity permitted by
            MARS INTERNATIONAL INDIA PVT. LTD. in this regard shall be entitled
            to use the responses received or any information in connection with
            the entry in any media for future promotional, marketing, publicity
            and any other purpose, without any permission and or payment to the
            Participant.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            All material submitted in connection with the Contest (whether
            written, audio, electronic or visual form, or a combination of
            those) or any photographs, video and/or film footage and/or audio
            recording taken of Participants are assigned to MARS INTERNATIONAL
            INDIA PVT. LTD. upon submission and become the property of MARS
            INTERNATIONAL INDIA PVT. LTD. exclusively. MARS INTERNATIONAL INDIA
            PVT. LTD. may use such material in any medium in any reasonable
            manner it sees fit. Copyright in any such material remains the sole
            property of MARS INTERNATIONAL INDIA PVT. LTD.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <li dir="ltr">
          <p dir="ltr">General Terms &amp; Conditions</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Each Participant represents and warrants that he/she is legally
            competent to enter into binding contracts under applicable laws. By
            taking part and/or entering into the Contest the Participant
            warrants that all information provided by the Participant regarding
            Participant's name, age, state, city, address, phone number, etc.,
            is true, correct, accurate and complete.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            The decision of MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier
            Sales Promotions Private Limited, as regards the selection of
            Winners/Confirmed Winners and distribution of the prizes shall be
            final, subject only to MARS INTERNATIONAL INDIA PVT. LTD.'s
            approval. No correspondence or any other claims whatsoever, in this
            regard will be entertained.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            No queries, claims, dispute, complaints, or grievances shall be
            entertained by MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier
            Sales Promotions Private Limited after 30 days from the date of the
            closure of the Contest.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            A Participant can win a maximum of 1 prize during the Contest
            Period. If a Participant has won any one of the prizes, then that
            Participant will not be eligible to win any further prizes.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            All applicable taxes will be borne by the Winner/Confirmed Winner.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Wherever gift tax is applicable, the tax amount will be borne by the
            Confirmed Winner.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            The prize cannot be exchanged and is not transferable and cannot be
            converted into cash.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            Premier Sales Promotions Private Limited shall provide the prize on
            an "as is where is basis" and without any warranty or guarantee
            concerning the quality, suitability or comfort, and MARS
            INTERNATIONAL INDIA PVT. LTD. and/or its associates, affiliates and/
            or its management, directors, officers, agents, representatives
            shall not be responsible for or liable (including but not limited to
            the product and service liabilities) for deficiency and/ or defect
            of any product/service and/or the prize or for any kind of
            consequential damages/ loss, in any manner whatsoever. If any
            Participant has any grievance with respect to the prize, he/she may
            contact the manufacturer directly.
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. reserves the right to change,
            defer, alter or cancel this Contest in part or full, or change any
            or all of the terms and conditions that are applicable, without
            giving prior intimation/notice of any kind and will not be liable
            for any consequential losses/damages. Any decision of MARS
            INTERNATIONAL INDIA PVT. LTD. in this regard and in general with
            respect to this Contest and the terms and conditions thereto shall
            be final, binding and conclusive.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            Nothing contained herein amounts to a commitment or representation
            by MARS INTERNATIONAL INDIA PVT. LTD. to conduct further contests.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <li dir="ltr">
          <p dir="ltr">
            The decision of MARS INTERNATIONAL INDIA PVT. LTD. in regard to the
            Contest and matters incidental thereto shall be final in all regards
            and no communication shall be entertained in this regard. None of
            the provisions of these General Terms &amp; Conditions and any other
            terms and conditions associated with the Contest shall be deemed to
            constitute a partnership or agency between any Participant and MARS
            INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited and the Participant shall not have the authority to
            bind MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales
            Promotions Private Limited in any manner whatsoever.
          </p>
        </li>
      </ol>
      <ol start={12}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited and/or any of its/their respective
            officers/employees/directors shall not be responsible for delayed,
            lost, mutilated, corrupted or illegible documents etc.
          </p>
        </li>
      </ol>
      <ol start={13}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall not be liable to perform any of its/their
            respective obligations under the Contest or in respect of the prize
            where it is unable to do so as a result of circumstances beyond
            its/their control in the nature of fire, explosions, natural
            calamities, state emergency, riots, or any other force majeure
            condition, etc., and shall not be liable to compensate the
            Participant in these circumstances.
          </p>
        </li>
      </ol>
      <ol start={14}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited shall not be accountable/liable for any
            disruptions/stoppages/interruptions or cancellation of the Contest
            due to any government restrictions.
          </p>
        </li>
      </ol>
      <ol start={15}>
        <li dir="ltr">
          <p dir="ltr">
            Failure to exercise or delay in exercising a right or remedy
            provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies on the part of
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited.
          </p>
        </li>
      </ol>
      <ol start={16}>
        <li dir="ltr">
          <p dir="ltr">
            MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier Sales Promotions
            Private Limited accepts no responsibility for late, lost,
            incomplete, incorrectly submitted, delayed, illegible, corrupted or
            misdirected entries, loss of SMS, loss of internet connectivity,
            unique codes, documents, demand drafts, claims or correspondence
            whether due to error, omission, alteration, tampering, unauthorized
            data hacking, deletion, theft, destruction, virus attack,
            transmission interruption, communications failure, hardware failure
            or otherwise. MARS INTERNATIONAL INDIA PVT. LTD. and/or Premier
            Sales Promotions Private Limited shall not be liable for any
            consequences of user error including (without limitation) costs
            incurred.
          </p>
        </li>
      </ol>
      <ol start={17}>
        <li dir="ltr">
          <p dir="ltr">
            If a Participant is dissatisfied with the Contest or the Contest
            rules and/or any content or any requirements of the Contest form,
            the materials available related thereto, or with these General Terms
            &amp; Conditions and/or any terms and conditions associated with the
            Contest, his/her sole and exclusive remedy is to not participate in
            the Contest.
          </p>
        </li>
      </ol>
      <ol start={18}>
        <li dir="ltr">
          <p dir="ltr">
            Subject to eligibility criteria of the Contest, if a person chooses
            to access the Contest Website from outside India, he/she shall do so
            on his/her own initiative and shall be responsible for compliance
            with applicable local laws of the country.
          </p>
        </li>
      </ol>
      <ol start={19}>
        <li dir="ltr">
          <p dir="ltr">
            The operational part of the Contest like delivery of prize shall be
            managed by the Premier Sales Promotions Private Limited. However,
            the product will be distributed through MARS INTERNATIONAL INDIA
            PVT. LTD. distributor/dealer.
          </p>
        </li>
      </ol>
      <ol start={20}>
        <li dir="ltr">
          <p dir="ltr">
            Participation in the Contest is purely voluntary and the same shall
            be construed as an acceptance of the terms and conditions stipulated
            herein.
          </p>
        </li>
      </ol>
      <ol start={21}>
        <li dir="ltr">
          <p dir="ltr">
            Validity of prize cannot be extended, OR new prize cannot be
            provided against the expired/unused codes.
          </p>
        </li>
      </ol>
      <ol start={22}>
        <li dir="ltr">
          <p dir="ltr">
            All disputes arising out of or in connection to the Contest are
            subject to exclusive jurisdiction of the courts in New Delhi, India
            only.
          </p>
        </li>
      </ol>
    </Popup>
  );
};

export default TermsPopup;
