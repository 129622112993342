// CashbackPayout.tsx
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import CameraIcon from "../../assets/images/camera-icon.png";
import MainLayout from "../../layouts/MainLayout";
import "./CashbackPayout.scss";
import { toBase64 } from "../../lib/utils";
import API from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts";
import { Trans, useTranslation } from "react-i18next";
import { error } from "console";
import Gate from "../../assets/images/hellgate.png";
import Gateleft from "../../assets/images/hellgateleft.png";

// Import statements...

interface CashbackPayoutProps {
  onSubmit: (values: any) => void;
}

const CashbackPayout: React.FC<CashbackPayoutProps> = ({ onSubmit }) => {
  const [isGateOpen, setGateOpen] = useState(false);
  const [isButtonHidden, setButtonHidden] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setGateOpen((val) => !val);
    setButtonHidden(true);

    // Navigate to the thank-you page after 5 seconds
    setTimeout(() => {
      navigate(ROUTES.THANK_YOU);
    }, 1500);
  };

  return (
    <MainLayout className="gate-page">
      <div className={`gate-container  ${isGateOpen ? "open" : ""}`}>
        <div className="gate1">
          <img src={Gateleft} />
        </div>
        <div className="gate2">
          <img src={Gate} />
        </div>
        {!isButtonHidden && (
          <button className="open-text" onClick={handleButtonClick}>
            TAP TO OPEN
          </button>
        )}
      </div>
    </MainLayout>
  );
};

export default CashbackPayout;
