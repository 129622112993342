import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="HOW TO REDEEM" className="howtoredeem-us-popup">
      <ol>
        <li dir="ltr">
          <p dir="ltr">Find Unique code inside the pack.</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Scan the QR code given on the pack or visit
            <br />
            <a href="http://www.snickerscontest.bigcityexperience.com">
              www.snickerscontest.
              <br />
              bigcityexperience.com
            </a>
            , enter Unique code, Name, Email ID, State and Phone Number to
            proceed for OTP authentication​.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Submit OTP to complete the participation.</p>
        </li>
      </ol>
    </Popup>
  );
};

export default HowTORedeemPopup;
