import { PropsWithChildren, useState } from "react";
import Logo from "../assets/images/Logo.png";
import banner from "../assets/images/banner.png";
import MenuIcon from "../assets/images/menu-icon.png";
import "./MainLayout.scss";
import SideMenu from "../components/SideMenu/SideMenu";
import { MODAL_TYPES, useGlobalModalContext } from "../helpers/GlobalModal";
import { useTranslation } from "react-i18next";
import { LANGUAGE, LANGUAGES, ROUTES } from "../lib/consts";
import { useNavigate } from "react-router-dom";

const MainLayout = ({
  children,
  className,
}: { className: string } & PropsWithChildren) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { showModal } = useGlobalModalContext();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  // console.log(i18n);
  return (
    <div className={"main-layout " + className}>
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />
      <header>
        {/*<select
          className="language-selection header-lang"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
          }}
        >
          {Object.keys(LANGUAGES).map((key) => {
            if (i18n.language === key) {
              return (
                <option key={key} value={key} selected>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            }

            return (
              <option key={key} value={key}>
                {LANGUAGES[key as LANGUAGE]}
              </option>
            );
          })}
        </select>*/}

        <img
          src={MenuIcon}
          onClick={() => setShowSideMenu(true)}
          alt="menu"
          className="menu-icon"
        />
        {/*<img
          src={Logo}
          alt="logo"
          className="logo"
          onClick={() => navigate(ROUTES.REGISTER)}
        />*/}
      </header>
      <div className="banner1">
        <img src={banner} alt="banner" className="banner" />
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default MainLayout;
