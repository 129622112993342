import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ROUTES } from "./lib/consts";
// import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import UploadSelfie from "./pages/UploadSelfie/UploadSelfie";
import ThankYou from "./pages/ThankYou/ThankYou";
import Completed from "./pages/Completed/Completed,";
import { useEffect } from "react";
import API from "./api";
import { useGlobalLoaderContext } from "./helpers/GlobalLoader";
import store from "./store/store";
import { setUserIdentification } from "./store/actions/authAction";
import { addCaptchaScript, logoutUser } from "./lib/utils";
import { useAuthentication } from "./hooks/useAuthentication";
import PrivateRoute from "./helpers/PrivateRoute";
import CashbackPayout from "./pages/CashbackPayout/CashbackPayout";

function App() {
  const { showLoader, hideLoader } = useGlobalLoaderContext();
  const { isLoggedIn } = useAuthentication();

  useEffect(() => {
    API.initialize(showLoader, hideLoader);
    API.createUser().then((response: any) => {
      // console.log(response);
      store.dispatch(setUserIdentification(response));
      if (!response.isLoggedIn && isLoggedIn) {
        logoutUser();
        toast.info("Your session has been expired");
      }
      // addCaptchaScript();
    });
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route index element={<Register />} />

        <Route
          path={ROUTES.PAYOUT_FORM}
          element={
            // <PrivateRoute>
            <CashbackPayout onSubmit={(values) => console.log(values)} />
            // </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.THANK_YOU}
          element={
            <PrivateRoute>
              <ThankYou />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.COMPLETED}
          element={
            <PrivateRoute>
              <Completed />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
