import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./ThankYou.scss";
import { useTranslation } from "react-i18next";
import repeatUserGif from "../../assets/images/anim/RepeatUsersOfferAnimation.gif";
import congratulationImage from "../../assets/images/anim/congrats-img.png";
import { ROUTES } from "../../lib/consts";

const ThankYou = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const handleClaimReward = () => {
    console.log("Handling claim reward...");

    console.log("Current state:", state);
    navigate(ROUTES.COMPLETED);
  };

  return (
    <MainLayout className="upload-selfie-page">
      <div className="congratulation-container">
        {state?.newUser ? (
          <>
            <p className="text1">{t("tyPage.newUser.text1")}</p>
            <p className="text2">{t("tyPage.newUser.text2")}</p>
            <div className="note">
              <p className="text3">{t("tyPage.newUser.text3")}</p>
              <p className="text4">{t("tyPage.note")}</p>
            </div>
          </>
        ) : (
          <>
            <p className="congratulation-text">
              {/*{t("tyPage.repeatUser.congratulationText")}*/}
              <p>
                Sorry, this promotion is currently not active in your region
              </p>
            </p>
            {/*<p className="text1">{t("tyPage.repeatUser.text1")}</p>*/}
            {/* Uncomment the following section if you want to display the repeatUserGif */}
            {/*<div className="note">
              <p className="text3">{t("tyPage.repeatUser.text3")}</p>
              <p className="text4">{t("tyPage.note")}</p>
              <img className="repeatUser_gif" src={repeatUserGif} alt="repeatUserGif" />
            </div>*/}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default ThankYou;
