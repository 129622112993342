import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import React, { useEffect } from "react";
import { ROUTES } from "../../lib/consts";
import "./Completed.scss";
import tickimg from "../../assets/images/tickimg.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";

import { useTranslation } from "react-i18next";

const Completed = () => {
  const { state } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // Track the Thank You event when the component mounts
    gtagTrackEvent(GA_EVENTS.Thank_You);
  }, []); // The empty dependency array ensures that the effect runs only once, similar to componentDidMount

  return (
    <MainLayout className="completed-page">
      <div className="congratulation-container">
        {state?.newUser ? (
          <>
            <p className="text1"></p>
            <p className="text2"></p>
            <div className="note">
              <p className="text3"></p>
              <p className="text4"></p>
            </div>
          </>
        ) : (
          <>
            <div className="congratulation-img">
              <img src={tickimg} />
              <p className="thankyou-text">Dear Customer</p>
            </div>
            <p className="text12">
              Thank you for participating in the Snickers Campaign. Your entry
              has been registered. You shall receive a call in case you are
              selected as a lucky winner.
              <br />
              Stay tuned!
              {/*<span className="yellow-text"> 24-48 business hours</span>*/}
            </p>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Completed;
